<template>
  <div class="vd_wrap">
    <!-- <el-divider></el-divider> -->
    <!-- <el-form
      ref="qrcdForm"
      :model="qrcdForm"
      :rules="rules"
      label-width="120px"
      size="mini"
    > -->
    <el-row>
      <el-col :md="8">
        <el-form-item label="物料图片" prop="imge_id" :rules="!disableMtrlAdd ? [{ required: true, message: ' ' }] : [{ required: false }]">
          <el-upload
            :disabled="disableMtrlAdd"
            class="avatar-uploader"
            :action="uploadUrl"
            :data="dataBody"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <imgPopover v-if="qrcdForm.imge_id" :imgeUrl="qrcdForm.imge_url" :imgeUrlO="qrcdForm.imge_urlO"></imgPopover>
            <i v-else class="el-icon-plus avatar-uploader-icon vg_pointer"></i>
          </el-upload>
          <span>仅支持jpg、png、jpeg格式且大小不能超过10MB</span>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <!-- <el-form-item label="物料名称" prop="mtrl_name" v-if="qrcdForm.mtrl_type === 0">
            <el-input
            :disabled="disableMtrlAdd"
              v-model="qrcdForm.mtrl_name"
              maxlength="30"
              show-word-limit
              placeholder="请填写物料名称"
            ></el-input>
          </el-form-item> -->
        <el-form-item label="安全库存" prop="mtrl_stock_limit">
          <el-input
            :disabled="disableMtrlAdd"
            v-model="qrcdForm.mtrl_stock_limit"
            @input="qrcdForm.mtrl_stock_limit = helper.keepTNum(qrcdForm.mtrl_stock_limit)"
            maxlength="7"
            show-word-limit
            placeholder="请填写库存警告数量"
          ></el-input>
        </el-form-item>
        <el-form-item label="物料单价" prop="mtrl_prod_price">
          <!-- :disabled="disableMtrlAdd" -->
          <el-input
            v-model="qrcdForm.mtrl_prod_price"
            @input="qrcdForm.mtrl_prod_price = helper.keepTNum1(qrcdForm.mtrl_prod_price)"
            @blur="qrcdForm.mtrl_prod_price = helper.calcPrice(qrcdForm.mtrl_prod_price, 7, 1000000)"
            maxlength="11"
            show-word-limit
            placeholder="请填写物料单价"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
        <el-form-item label="物料单位" prop="mtrl_unit">
          <el-select :disabled="disableMtrlAdd" v-model="qrcdForm.mtrl_unit" placeholder="请选择物料单位" clearable>
            <el-option v-for="item in mtrlTypeGroupOpt" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :md="8">
        <!-- <el-form-item label="供应商户" prop="supp_id">
            <el-select
              :disabled="disableMtrlAdd"
              v-model="qrcdForm.supp_id"
              placeholder="请选择供应商"
              clearable
              @visible-change="suppChange"
            >
              <el-option
                v-for="item in suppType"
                :key="item.supp_id"
                :label="item.supp_name"
                :value="item.supp_id"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
        <!-- <el-form-item label="颜色大类" prop="colr_class" class="itemBtn">
            <el-select
            :disabled="disableMtrlAdd || qrcdForm.mtrl_type === 0"
              v-model="qrcdForm.colr_class"
              placeholder="请选择颜色大类"
            >
              <el-option
                v-for="item in optnColorList"
                :key="item.param1"
                :label="item.param1"
                :value="item.param1"
              >
              </el-option>
            </el-select>
          </el-form-item> -->
        <el-form-item label="物料成分" prop="mtrl_comp">
          <el-select :disabled="disableMtrlAdd" v-model="qrcdForm.mtrl_comp" placeholder="请选择物料成分" clearable filterable>
            <el-option v-for="item in compType" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
          </el-select>
        </el-form-item>
        <!-- :rules="qrcdForm.mtrl_width == 0 ?  [{ required: false }] : [{required:true}]"  -->
        <el-form-item label="其他备注" prop="mtrl_remark">
          <el-input
            :disabled="disableMtrlAdd"
            type="textarea"
            rows="1"
            v-model="qrcdForm.mtrl_remark"
            maxlength="150"
            show-word-limit
            placeholder="请填写物料备注"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="物料规格"
          prop="mtrl_spec"
          :rules="qrcdForm.mtrl_type !== 0 ? [{ required: true, message: ' ' }] : [{ required: false }]"
        >
          <el-input
            :disabled="disableMtrlAdd"
            type="textarea"
            rows="1"
            v-model="qrcdForm.mtrl_spec"
            maxlength="50"
            show-word-limit
            placeholder="请填写物料规格"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <!-- </el-form> -->
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mtrlAPI } from '@api/modules/mtrl';
import { optnAPI } from '@api/modules/optn';
import { suppAPI } from '@api/modules/supp';
import { colrAPI } from '@/api/modules/colr.js';
import { imgeAPI } from '@api/modules/imge';
import helper from '@assets/js/helper';
import imgPopover from '@/views/component/imgPopover';

export default {
  name: 'mtrlAddMain',
  components: {
    imgPopover
  },
  props: {
    qrcdForm: {
      type: Object,
      required: true
    },
    rules: {
      type: Object,
      required: true
    },
    disableMtrlAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      mtrlTypeGroupOpt: [],
      suppType: [],
      compType: [],
      optnColorList: []
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getMtrlType();
      this.getCompType();
      this.getSuppsList();
      this.getOptnColor();
    },
    getImgId() {},
    // 商户下拉框下拉
    suppChange() {
      this.getSuppsList();
    },
    //获取颜色大类option
    getOptnColor() {
      get(optnAPI.getAllContent, { perm_id: 10010 })
        .then(res => {
          if (res.data.code === 0) {
            this.optnColorList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取成分
    getCompType() {
      get(optnAPI.getAllContent, { perm_id: 10008 })
        .then(res => {
          if (res.data.code === 0) {
            this.compType = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取单位
    getMtrlType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlTypeGroupOpt = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取供应商信息
    getSuppsList() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppType = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //同步颜色 大类
    clickSyncColor() {
      get(colrAPI.getColrClassByPantone, {
        colr_pantone: this.qrcdForm.mtrl_color + 'C'
      })
        .then(res => {
          if (res.data.code === 0) {
            this.qrcdForm.colr_class = res.data.data.form.colr_class;
            //  this.$message.success('同步'+res.data.msg)
            return;
          } else if (res.data.code === 2) {
            this.qrcdForm.colr_class = null;
            this.$message.warning('未匹配到该颜色大类');
            return;
          }
          this.$message.warning(res.data.msg);
        })
        .catch(res => {
          this.$message.warning(res.data.msg);
        });
    },
    // 成功上传
    handleAvatarSuccess(res) {
      if (res.code === 0) {
        let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
        let urlEng = JSON.parse(JSON.stringify(res.data.imge_url));
        this.qrcdForm.imge_id = res.data.imge_id;
        this.qrcdForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
        this.qrcdForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      } else {
        let mg = res.msg;
        let tp = 'error';
        this.$message({ message: mg, type: tp });
      }
    },
    //表单重置
    resetForm(formName = 'qrcdForm') {
      this.$refs[formName].resetFields();
    },
    // 上传头像前
    beforeAvatarUpload() {},
    mtrlNoInput() {
      this.qrcdForm.mtrl_no = this.helper.keepEnglish(this.qrcdForm.mtrl_no).toUpperCase();
      this.qrcdForm.mtrl_no = this.qrcdForm.mtrl_no.substr(0, 2);
    },
    typeChange() {
      if (this.qrcdForm.mtrl_type != 0) {
        this.qrcdForm.mtrl_color = '';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}

.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_cussor {
  cursor: default;
}
::v-deep .mtrlNo .el-input-group__append {
  width: 70%;
  padding: 0;
}
::v-deep .mtrlcolor .el-input-group__append {
  width: 70%;
  padding: 0;
}
::v-deep .el-input-group__append {
  color: #000000;
}
.vd_color_red {
  color: red;
}
.vd_wrap {
  margin-top: 2px;
}
::v-deep .itemBtn .el-form-item__content {
  display: flex;
}
</style>
