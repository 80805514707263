<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('qrcdForm')" @cancel="cancel" :stateFlag="false"></add-header>
    <el-form ref="qrcdForm" :model="qrcdForm" :rules="rules" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="物料类型" prop="mtrl_type">
            <el-select
              :disabled="emptyDisable"
              v-model="qrcdForm.mtrl_type"
              placeholder="请选择物料类型"
              clearable
              @change="mtrlTypeChange"
            >
              <el-option v-for="item in qrcdType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="颜色大类" prop="colr_class" class="itemBtn">
            <el-select filterable :disabled="disableMtrlColorClass" v-model="qrcdForm.colr_class" placeholder="请选择颜色大类">
              <el-option v-for="item in optnColorList" :key="item.param1" :label="item.param1" :value="item.param1"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="采购申请单号" prop="sour_no">
            <el-input disabled maxlength="10" v-model="qrcdForm.sour_no" placeholder="请填写采购申请单号" show-word-limit>
              <template slot="append">
                <el-link type="primary" @click="sourImport">导入采购申请单号</el-link>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-tooltip class="item" effect="dark" content="仅限两位大写英文字母" placement="top-start">
            <el-form-item label="物料编号" prop="mtrl_no">
              <el-input
                maxlength="2"
                :disabled="emptyDisable"
                v-model="qrcdForm.mtrl_no"
                @input="mtrlNoInput"
                placeholder="请填写物料编号"
                show-word-limit
              >
                <template slot="append">
                  <!-- v-if="qrcdForm.mtrl_color" -->
                  <span class="color_span" v-if="qrcdForm.mtrl_type === 0">{{ qrcdForm.mtrl_color }}C</span>
                  <el-link type="primary" @click="mtrlImport">导入物料</el-link>
                </template>
              </el-input>
            </el-form-item>
          </el-tooltip>
        </el-col>
        <el-col :md="8">
          <el-form-item label="物料名称" prop="mtrl_name">
            <el-input
              :disabled="disableMtrlType"
              v-model="qrcdForm.mtrl_name"
              maxlength="30"
              show-word-limit
              placeholder="请填写物料名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="供应商户" prop="supp_id">
            <el-select v-model="qrcdForm.supp_id" placeholder="请选择供应商" clearable filterable @visible-change="suppChange">
              <!-- :disabled="disableMtrlAdd" -->
              <el-option v-for="item in suppType" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="qrcdForm.mtrl_type === 0">
        <el-col :md="8">
          <el-form-item
            label="物料门幅"
            prop="mtrl_width"
            :rules="qrcdForm.mtrl_type == 0 ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-input
              :disabled="emptyDisable"
              v-model="qrcdForm.mtrl_width"
              maxlength="9"
              show-word-limit
              @blur="qrcdForm.mtrl_width = helper.calcPrice(qrcdForm.mtrl_width, 4, 10000)"
              @input="qrcdForm.mtrl_width = helper.keepTNum1(qrcdForm.mtrl_width)"
              placeholder="请填写物料门幅"
            >
              <template slot="append">米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item
            label="物料毛高"
            prop="mtrl_thick"
            :rules="qrcdForm.mtrl_type == 0 ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-input
              :disabled="emptyDisable"
              v-model="qrcdForm.mtrl_thick"
              @input="qrcdForm.mtrl_thick = helper.keepTNum1(qrcdForm.mtrl_thick)"
              @blur="qrcdForm.mtrl_thick = helper.retain1(qrcdForm.mtrl_thick, 2, 1000)"
              maxlength="6"
              show-word-limit
              placeholder="请填写物料毛高"
            >
              <template slot="append">毫米</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item
            label="潘通色号"
            prop="mtrl_color"
            :rules="qrcdForm.mtrl_type == 0 ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-tooltip class="item" effect="dark" content="仅限输入四位" placement="top-start">
              <el-input
                :disabled="emptyDisable"
                class="mtrlcolor"
                v-model="qrcdForm.mtrl_color"
                @input="mtrlColorInput"
                @blur="changeMtrlColor"
                show-word-limit
                maxlength="4"
                placeholder="仅限输入四位"
              >
                <template slot="append"> <span v-if="qrcdForm.mtrl_color != ''">C</span></template>
              </el-input>
            </el-tooltip>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="qrcdForm.mtrl_type !== 0">
        <el-col :md="8">
          <el-form-item label="物料规格" prop="mtrl_spec">
            <el-input
              :disabled="emptyDisable"
              v-model="qrcdForm.mtrl_spec"
              maxlength="50"
              type="textarea"
              rows="1"
              show-word-limit
              placeholder="请填写物料规格"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8" v-if="qrcdForm.mtrl_type === 0">
          <el-form-item
            label="物料克重"
            prop="mtrl_weight"
            :rules="qrcdForm.mtrl_type == 0 ? [{ required: true, message: ' ' }] : [{ required: false }]"
          >
            <el-input
              :disabled="emptyDisable"
              v-model="qrcdForm.mtrl_weight"
              @input="qrcdForm.mtrl_weight = helper.keepTNum1(qrcdForm.mtrl_weight)"
              @blur="qrcdForm.mtrl_weight = helper.retain1(qrcdForm.mtrl_weight, 2, 10000)"
              maxlength="7"
              show-word-limit
              placeholder="请填写物料克重"
            >
              <template slot="append">克</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="12">
          <div class="but_col">
            <el-button type="primary" size="mini" :disabled="emptyDisable" @click="matchingQrcd">匹配</el-button>
            <el-button type="info" size="mini" @click="emptyQrcd">清空</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8">
          <el-form-item label="所属部门" prop="belo_dept_id">
            <el-select v-model="qrcdForm.belo_dept_id" placeholder="请选择所属部门" @change="qrcdDeptChange" clearable>
              <el-option v-for="item in option" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="库位" prop="qrcd_bin">
            <el-select filterable v-model="qrcdForm.qrcd_bin" placeholder="请选择库位" :disabled="!qrcdForm.belo_dept_id">
              <el-option v-for="item in binList" :key="item.id" :label="item.param1" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="数量" prop="qrcd_num">
            <el-input
              v-model="qrcdForm.qrcd_num"
              @blur="qrcdForm.qrcd_num = helper.calcPrice(qrcdForm.qrcd_num, 4, 1000000)"
              maxlength="11"
              show-word-limit
              placeholder="请填写数量"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <QrcdAddMtrl :qrcdForm="qrcdForm" :rules="rules" :disableMtrlAdd="disableMtrlAdd" ref="QrcdAddMtrl"></QrcdAddMtrl>
      <!-- <el-row>
        <el-col :md="7">
          <div class="vd_hidden">111</div>
        </el-col>
        <el-col :md="16">
          <div>
            <div class="vd_but vg_mb_8">
              <el-button plain size="small" @click="addRow()">新增</el-button>
            </div>
            <MtrlImport :mtrlForm="qrcdForm"></MtrlImport>
          </div>
        </el-col>
      </el-row> -->
      <el-dialog :title="title" :visible.sync="mtrlFlag" width="70%">
        <QrcdMtrlImport @falseMtrl="falseMtrl" @mtrlChanel="mtrlChanel" @mtrlConfirm="mtrlConfirm" ref="QrcdMtrlImport"></QrcdMtrlImport>
      </el-dialog>
      <el-dialog :title="title1" :visible.sync="sourFlag" width="70%">
        <QrcdSourImport @falseSour="falseSour" @sourChanel="sourChanel" @sourConfirm="sourConfirm" ref="QrcdSourImport"></QrcdSourImport>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { qrcdAPI } from '@api/modules/qrcd';
import { optnAPI } from '@api/modules/optn';
import { mtrlAPI } from '@api/modules/mtrl';
import { suppAPI } from '@api/modules/supp';
import { colrAPI } from '@/api/modules/colr.js';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import QrcdMtrlImport from '@/views/StockManagement/QrcdManage/TabChild/Componet/QrcdMtrlImport.vue';
import QrcdSourImport from '@/views/StockManagement/QrcdManage/TabChild/Componet/QrcdSourImport.vue';
import QrcdAddMtrl from '@/views/StockManagement/QrcdManage/TabChild/Componet/QrcdAddMtrl.vue';
// import MtrlImport from "./Componet/MdrlImport.vue"

export default {
  name: 'qrcdAddMain',
  components: {
    addHeader,
    inputUser,
    QrcdMtrlImport,
    QrcdSourImport,
    QrcdAddMtrl
    // MtrlImport
  },
  data() {
    return {
      rules: {
        mtrl_no: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_name: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_unit: [{ required: true, trigger: 'change', message: ' ' }],
        mtrl_type: [{ required: true, trigger: 'change', message: ' ' }],
        qrcd_num: [{ required: true, trigger: 'blur', message: ' ' }],
        qrcd_bin: [{ required: true, trigger: 'change', message: ' ' }],
        belo_dept_id: [{ required: true, trigger: 'change', message: ' ' }],
        mtrl_thick: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        // colr_class: [
        //   { required: true, trigger: ["blur", "change"], message: " " },
        // ],
        mtrl_comp: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_stock_limit: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_prod_price: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_width: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        mtrl_color: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        imge_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        supp_id: [{ required: true, trigger: 'change', message: ' ' }],
        mtrl_weight: [{ required: true, trigger: 'blur', message: ' ' }],
        mtrl_spec: [{ required: true, trigger: 'blur', message: ' ' }]
      },
      qrcdForm: {
        mtrl_id: null,
        sour_mtrl_id: null,
        sour_no: null,
        mtrl_no: null,
        mtrl_name: null,
        mtrl_unit: null,
        mtrl_type: 0,
        qrcd_num: null,
        qrcd_bin: null,
        user_id: null,
        stff_id: null,
        dept_id: null,
        status: 0,
        mtrl_spec: '',
        mtrl_color: '',
        mtrl_weight: '',
        mtrl_width: null,
        imge_id: null,
        imge_url: '',
        imge_urlO: '',
        mtrl_thick: null,
        mtrl_comp: null,
        mtrl_stock_limit: 0,
        mtrl_prod_price: '',
        mtrl_remark: '',
        supp_id: '',
        colr_class: '',
        key: 0,
        belo_dept_id: null //所属部门
      },
      binList: [],
      qrcdType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
        // {id:3,label:'加工面料'},
      ],
      mtrlTypeGroupOpt: [],
      suppType: [],
      mtrlFlag: false,
      sourFlag: false,
      title: '物料信息',
      title1: '采购信息',
      option: [],
      binListCopy: [],
      disableMtrlAdd: true,
      disableMtrlType: true,
      disableMtrlColorClass: true,
      emptyDisable: false,
      optnColorList: []
    };
  },
  watch: {},
  created() {
    this.initData();
  },
  methods: {
    initData() {
      this.getQrcdType();
      this.getBinList();
      this.getDepartment();
      this.getOptnColor();
      this.getSuppsList();
    },
    // 获取供应商信息
    getSuppsList() {
      get(suppAPI.getSuppsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.suppType = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 商户下拉框下拉
    suppChange() {
      this.getSuppsList();
    },
    //获取颜色大类option
    getOptnColor() {
      get(optnAPI.getAllContent, { perm_id: 10010 })
        .then(res => {
          if (res.data.code === 0) {
            this.optnColorList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 获取库位信息
    getBinList() {
      get(optnAPI.getAllContent, { perm_id: 10006 })
        .then(res => {
          if (res.data.code === 0) {
            this.binList = res.data.data;
            this.binListCopy = JSON.parse(JSON.stringify(res.data.data));
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取单位
    getQrcdType() {
      get(optnAPI.getAllContent, { perm_id: 10005 })
        .then(res => {
          if (res.data.code === 0) {
            this.mtrlTypeGroupOpt = res.data.data;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取打样部门
    getDepartment() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            let arr = [];
            res.data.data.form.optn_cntt_list.forEach(item => {
              arr.push({
                value: item.param2,
                label: item.param1
              });
            });
            this.option = arr;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              if (this.disableMtrlAdd) {
                this.saveInfo();
              } else {
                this.saveInfoC();
              }
            } else {
              this.$message({ type: 'warning', message: '请填入必填信息' });
              console.log('error submit!');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let qrcdForm = {};
      let qrcdFormCopy = JSON.parse(JSON.stringify(this.qrcdForm));
      qrcdForm = Object.assign(qrcdFormCopy, staffForm);
      if (qrcdForm.mtrl_type === 0) {
        qrcdForm.mtrl_no = qrcdForm.mtrl_no + qrcdForm.mtrl_color + 'C';
        qrcdForm.mtrl_color = qrcdForm.mtrl_color + 'C';
      } else {
        // qrcdForm.mtrl_no = qrcdForm.mtrl_no
        qrcdForm.mtrl_color = '';
      }
      post(qrcdAPI.addQrcd, qrcdForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump(`/qrcd_list?perm_id=${permId}`);
            this.resetForm('qrcdForm');
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '库位中已有此物料'
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    saveInfoC() {
      const staffForm = this.$refs.userData.getData();
      let qrcdForm = {};
      let qrcdFormCopy = JSON.parse(JSON.stringify(this.qrcdForm));
      qrcdForm = Object.assign(qrcdFormCopy, staffForm);
      if (qrcdForm.mtrl_type === 0) {
        qrcdForm.mtrl_no = qrcdForm.mtrl_no + qrcdForm.mtrl_color + 'C';
        qrcdForm.mtrl_color = qrcdForm.mtrl_color + 'C';
      } else {
        // qrcdForm.mtrl_no = qrcdForm.mtrl_no
        qrcdForm.mtrl_color = '';
      }
      qrcdForm.mtrl_id = null;
      post(qrcdAPI.addMtrlToQrcd, qrcdForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump(`/qrcd_list?perm_id=${permId}`);
            this.resetForm('qrcdForm');
          } else if (res.data.code === 7) {
            this.$message({
              type: 'error',
              message: '库位中已有此物料'
            });
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('qrcdForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 新增字表数据
    addRow() {
      let temp = {};
      if (this.mtrlForm.supp_id) {
        temp.supp_id = this.mtrlForm.supp_id;
      } else {
        temp.supp_id = null;
      }
      if (this.mtrlForm.mtrl_prod_price) {
        temp.mtrl_prod_price = this.mtrlForm.mtrl_prod_price;
      } else {
        temp.mtrl_prod_price = '0.0000';
      }
      temp.mtrl_info_checked = false;
      temp.key = this.mtrlForm.mtrl_info_list.length;
      this.mtrlForm.mtrl_info_list.push(temp);
    },
    // 物料类型变化
    mtrlTypeChange(value) {
      this.qrcdForm.mtrl_name = null;
      if (value === 0) {
        this.disableMtrlType = true;
      } else {
        this.disableMtrlType = false;
        this.qrcdForm.mtrl_color = '';
      }
    },
    // 保留位数
    baretaino() {
      this.qrcdForm.mtrl_no = this.qrcdForm.mtrl_no.substr(0, 2);
      this.qrcdForm.mtrl_thick = this.helper.reservedDigits(this.qrcdForm.mtrl_thick);
      this.qrcdForm.mtrl_width = this.helper.reservedDigits(this.qrcdForm.mtrl_width);
      this.qrcdForm.mtrl_prod_price = this.helper.haveFour(this.qrcdForm.mtrl_prod_price);
      this.qrcdForm.mtrl_weight = this.helper.reservedDigits(this.qrcdForm.mtrl_weight);
      this.qrcdForm.mtrl_color = this.qrcdForm.mtrl_color.substring(0, this.qrcdForm.mtrl_color.indexOf('C'));
    },
    // 调用匹配接口
    getMtrlByInfo() {
      let params = {};
      params.mtrl_type = this.qrcdForm.mtrl_type;
      params.mtrl_no = this.qrcdForm.mtrl_no;
      if (this.qrcdForm.mtrl_type === 0) {
        params.mtrl_width = this.qrcdForm.mtrl_width;
        params.mtrl_color = this.qrcdForm.mtrl_color + 'C';
        params.mtrl_thick = this.qrcdForm.mtrl_thick;
        params.mtrl_weight = this.qrcdForm.mtrl_weight;
      } else {
        params.mtrl_name = this.qrcdForm.mtrl_name;
        params.mtrl_spec = this.qrcdForm.mtrl_spec;
      }
      get(mtrlAPI.getMtrlByInfo, params)
        .then(res => {
          if (res.data.code === 0) {
            let form = res.data.data.form;
            this.qrcdForm = Object.assign(this.qrcdForm, form);
            let urlEng = JSON.parse(JSON.stringify(this.qrcdForm.imge_url));
            let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
            this.qrcdForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
            this.qrcdForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
            if (!form.colr_class) {
              this.disableMtrlColorClass = false;
            } else {
              this.disableMtrlColorClass = true;
            }
            this.baretaino();
            this.disableMtrlAdd = true;
            this.disableMtrlType = true;
            this.emptyDisable = true;
          } else if (res.data.code === 2) {
            let mg = '该物料未存在，请把内容补充完整保存添加';
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.disableMtrlAdd = false;
            if (this.qrcdForm.mtrl_type === 0) {
              this.disableMtrlType = false;
            } else {
              this.disableMtrlType = true;
            }
            this.emptyDisable = true;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 匹配
    matchingQrcd() {
      if (this.qrcdForm.mtrl_type === 0) {
        if (
          this.qrcdForm.mtrl_no &&
          this.qrcdForm.mtrl_width &&
          this.qrcdForm.mtrl_thick &&
          this.qrcdForm.mtrl_color &&
          this.qrcdForm.mtrl_weight
        ) {
          this.getMtrlByInfo();
        } else {
          this.$message.warning('请把物料编号、物料门幅、物料毛高、物料克重和潘通色号补充完整');
        }
      } else {
        if (this.qrcdForm.mtrl_no && this.qrcdForm.mtrl_name && this.qrcdForm.mtrl_spec) {
          this.getMtrlByInfo();
        } else {
          this.$message.warning('请把物料编号、物料名称和物料规格补充完整');
        }
      }
    },
    // 清空
    emptyQrcd() {
      this.resetForm('qrcdForm');
      this.emptyDisable = false;
    },
    // 物料导入
    mtrlImport() {
      this.mtrlFlag = true;
    },
    // 物料导入取消
    mtrlChanel() {
      this.mtrlFlag = false;
    },
    falseMtrl() {
      this.mtrlChanel();
    },
    // 物料导入确认
    mtrlConfirm(val) {
      let value = JSON.parse(JSON.stringify(val));
      this.qrcdForm = Object.assign(this.qrcdForm, value);
      this.qrcdForm.sour_no = null;
      let urlEng = JSON.parse(JSON.stringify(this.qrcdForm.imge_url));
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
      this.qrcdForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
      this.qrcdForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      if (!this.qrcdForm.colr_class) {
        this.disableMtrlColorClass = false;
      } else {
        this.disableMtrlColorClass = true;
      }
      this.baretaino();
      this.emptyDisable = true;
      this.mtrlFlag = false;
    },
    // 采购物料明细导入
    sourImport() {
      this.sourFlag = true;
    },
    // 采购物料取消
    sourChanel() {
      this.sourFlag = false;
    },
    falseSour() {
      this.sourChanel();
    },
    // 采购物料明细导入确认
    sourConfirm(val) {
      let value = JSON.parse(JSON.stringify(val));
      this.qrcdForm = Object.assign(this.qrcdForm, value);
      let urlEng = JSON.parse(JSON.stringify(this.qrcdForm.imge_url));
      let updateTime = JSON.parse(JSON.stringify(new Date().getTime()));
      this.qrcdForm.imge_url = this.helper.picUrl(urlEng, 's', updateTime);
      this.qrcdForm.imge_urlO = this.helper.picUrl(urlEng, 'l', updateTime);
      if (!this.qrcdForm.colr_class) {
        this.disableMtrlColorClass = false;
      } else {
        this.disableMtrlColorClass = true;
      }
      this.baretaino();
      this.emptyDisable = true;
      this.mtrlFlag = false;
      this.sourFlag = false;
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.qrcdForm.imge_url = null;
      this.qrcdForm.imge_urlO = null;
      this.qrcdForm.imge_id = null;
      this.disableMtrlAdd = true;
      this.disableMtrlType = true;
      this.emptyDisable = false;
    },
    qrcdDeptChange(val) {
      //将库位清空
      this.qrcdForm.qrcd_bin = null;
      let arr = this.binListCopy.filter(item => {
        return item.param2 === val;
      });
      this.binList = arr;
    },
    mtrlColorInput() {
      this.qrcdForm.mtrl_color = this.helper.keepTNum(this.qrcdForm.mtrl_color);
      this.qrcdForm.mtrl_color = this.qrcdForm.mtrl_color.substr(0, 4);
    },
    // 物料编号失去焦点匹配颜色大类
    changeMtrlColor() {
      if (this.qrcdForm.mtrl_type === 0 && this.qrcdForm.mtrl_color.length !== 0) {
        this.clickSyncColor();
      }
    },
    //同步颜色 大类
    clickSyncColor() {
      get(colrAPI.getColrClassByPantone, {
        colr_pantone: this.qrcdForm.mtrl_color + 'C'
      })
        .then(res => {
          if (res.data.code === 0) {
            this.qrcdForm.colr_class = res.data.data.form.colr_class;
            this.disableMtrlColorClass = true;
            //  this.$message.success('同步'+res.data.msg)
            return;
          } else if (res.data.code === 2) {
            this.qrcdForm.colr_class = null;
            this.$message.warning('未匹配到该颜色大类');
            this.disableMtrlColorClass = false;
            return;
          }
          this.$message.warning(res.data.msg);
        })
        .catch(res => {
          this.$message.warning(res.data.msg);
        });
    },
    // 物料编号截取
    mtrlNoInput() {
      this.qrcdForm.mtrl_no = this.helper.keepEnglish(this.qrcdForm.mtrl_no).toUpperCase();
      this.qrcdForm.mtrl_no = this.qrcdForm.mtrl_no.substr(0, 2);
    }
  }
};
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.color_span {
  display: inline-block;
  width: 40px;
  height: 100%;
  border-right: #dcdfe6 1px solid;
  margin-right: 10px;
  box-sizing: border-box;
}
.but_col {
  width: 300px;
  padding-left: 100px;
  margin: 5px 0;
}
.vd_hidden {
  visibility: hidden;
}
</style>
